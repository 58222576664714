/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import { } from 'jquery-match-height';

// Add custom hasChildren selector
$.expr[':'].hasChildren = function (element, index, selector) {
	return $(element).children().length >= (selector[3] || 1);
};

import 'responsive-bp/src/js/responsive.core.js';
import "responsive-bp/src/js/responsive.dropdown.js";
import AOS from 'aos/dist/aos.js';

$(function () {
	// Calculate
	var timeout = false,
		timeoutDelay = 250;

	function calculateSizes() {
		document.documentElement.style.setProperty('--vh', `${document.documentElement.clientHeight}px`);
		document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	}

	calculateSizes();

	window.addEventListener('resize', function () {
		clearTimeout(timeout);
		timeout = setTimeout(calculateSizes, timeoutDelay);
	});

	// Responsive menu
	$('.toggler').each(function () {
		$(this).click(toggleMenu);
	});

	function toggleMenu() {
		var toggler = $('.toggler');
		var responsiveNav = $('.responsive-nav');

		$(document.body).toggleClass('menu-open');
		responsiveNav.toggleClass('-open');
		toggler.toggleClass('is-active');
	}

	// Update fixed header height
	var updateHeight = function () {
		var height = 0, header = $('header .top').eq(0);
		if (header && header.css('position') == 'fixed') {
			height = header.outerHeight();
		}

		$(document.body).css('padding-top', height);
	};
	$(window).on('resize orientationchange', updateHeight);
	updateHeight();

	// Set same height
	$('.same-height').matchHeight({
		property: 'height'
	});

	// Delayed dropdown close on mouseout
	$('nav.main [data-dropdown-target]').each(function () {
		var $this = $(this);
		var timerId;

		$this.parent().mouseleave(function () {
			timerId = setTimeout(function () {
				$this.dropdown('hide');
				$this.blur();
			}, 400);
		}).mouseenter(function () {
			clearTimeout(timerId);
		});
	});

	// Search toggler
	$('.search').each(function () {
		var $this = $(this),
			toggler = $this.find('.search__toggler');

		toggler.click(function () {
			$('#search-input').focus();
			$this.toggleClass('search--closed');
			toggler.toggleClass('search__toggler--closed');
		}).appendTo($this);
	});
	
	// Open language on touch
	$('.languages').each(function () {
		var $this = $(this);

		$this.on('touchend', function (e) {
			$this.addClass('open');
			e.stopPropagation();
		});

		$(document).on('touchend', function () {
			$this.removeClass('open');
		});
	});

	var wrapSwiper = function (element, childrenSelector = null, pagination = false, navigation = false) {
		var $element = $(element);
		$element.children(childrenSelector).addClass('swiper-slide');
		$element.wrapInner('<div class="swiper-wrapper" />');

		if (pagination) {
			$element.append('<div class="swiper-pagination" />');
		}

		if (navigation) {
			$element.append('<a class="swiper-button-prev"><i class="icon icon-chevron-left"></i></a>');
			$element.append('<a class="swiper-button-next"><i class="icon icon-chevron-right"></i></a>');
		}
	};

	// Init Animate On Scroll
	AOS.init({
		once: true,
		easing: 'ease-in-sine'
	});

	// Masonry
	$('.overview .-gutter-40').each(async function () {
		const Masonry = (await import('masonry-layout')).default;

		new Masonry('.overview .-gutter-40', {
			itemSelector: '.overview .item'
		});
	});

	// Slideshow
	$('.slideshow:hasChildren(2)').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		wrapSwiper(this, null, false, true);
		new Swiper(this, {
			keyboard: true,
			loop: true,
			effect: 'fade',
			speed: 1000,
			autoplay: {
				delay: 5000
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			on: {
				init: function () {
					// Refresh AOS, because activating Swiper changes layout
					AOS.refresh();
				}
			}
		});
	});

	$('.slider:hasChildren(2)').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		wrapSwiper(this, null, false, true);
		new Swiper(this, {
			keyboard: true,
			loop: true,
			effect: 'slide',
			speed: 1000,
			autoplay: {
				delay: 5000
			},
			slidesPerView: 3,
			spaceBetween: 40,
			// Responsive breakpoints
			breakpoints: {
				1024: {
					slidesPerView: 2
				},
				640: {
					slidesPerView: 1
				}
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			on: {
				init: function () {
					// Refresh AOS, because activating Swiper changes layout
					AOS.refresh();
				}
			}
		});
	});

	$('.slider-shortcuts:hasChildren(2)').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		wrapSwiper(this, null, false, true);
		new Swiper(this, {
			keyboard: true,
			loop: false,
			effect: 'slide',
			speed: 1000,
			autoplay: {
				delay: 5000
			},
			slidesPerView: 4,
			spaceBetween: 40,
			// Responsive breakpoints
			breakpoints: {
				1440: {
					slidesPerView: 3
				},
				1024: {
					slidesPerView: 2
				},
				640: {
					slidesPerView: 1
				}
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			on: {
				init: function () {
					// Refresh AOS, because activating Swiper changes layout
					AOS.refresh();
				}
			}
		});
	});

	// Lightbox
	$('main:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Update forms
	var updateForms = function (element) {
		var $element = $(element);

		// Form focus
		$element.find('.umbraco-forms-field:not(.checkbox) :input').each(function () {
			var $this = $(this),
				field = $this.parent('.umbraco-forms-field');

			$this.on({
				change: function () {
					if ($this.val().length) {
						field.addClass('has-value');
					} else {
						field.removeClass('has-value');
					}
				},
				focus: function () {
					field.addClass('focus');
				},
				blur: function () {
					field.removeClass('focus');
				}
			}).trigger('change');
		});

		// Jump to submitted form
		$element.find('.umbraco-forms-form[id]:has(form)').each(function () {
			var $this = $(this),
				form = $this.find('form');

			form.attr('action', form.attr('action') + '#' + $this.attr('id'));
		});
	};
	updateForms(document);

	// File uploads
	var inputs = document.querySelectorAll('.fileupload input');
	Array.prototype.forEach.call(inputs, function (input) {
		var label = input.nextElementSibling,
			labelVal = label.innerHTML;

		input.addEventListener('change', function (e) {
			var fileName = '';
			if (this.files && this.files.length > 1)
				fileName = (this.getAttribute('data-multiple-caption') || '').replace('{0}', this.files.length);
			else
				fileName = e.target.value.split('\\').pop();

			if (fileName)
				$(label).text(fileName);
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 250);

			return false;
		}
	});

	// Change target for external sites
	var getDomainName = function (hostName) {
		return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
	};
	$('a:not([target])').filter(function () {
		var link = $(this);

		if (link.filter('[rel~="external"]').length) return true; // Link rel attribute contains 'external'

		var href = link.attr('href');
		if (new RegExp('\.pdf$', 'i').test(href)) return true; // Link to PDF file

		if (new RegExp('^https?://', 'i').test(href)) {
			var linkHostName = getDomainName(href.substring(href.indexOf('/') + 2, href.indexOf('/', 8)));
			if (!new RegExp('(^|\.)' + getDomainName(document.location.hostname) + '$', 'i').test(linkHostName)) {
				// Link is to different domain name
				return true;
			}
		}

		return false;
	}).attr('target', '_blank');
});